<div class="mt-86 overflow-hidden">
	<!-- Hero section -->
	<section>
		<ngb-carousel [pauseOnHover]="true" [showNavigationArrows]="mobile"
			[showNavigationIndicators]="false" [interval]="10000">
		<ng-template ngbSlide> <!-- <div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0 text-left">
				<h1 class="font-weight-light mb-0">
					<a target="_blank" href="https://app.genailia.com">Try <b>Genailia</b>
					</a>
				</h1>
				<h2>
					- Generative AI, <br />Language Interfaces,
				</h2>
				<h3
					class="font-weight-bold lh-1 d-flex align-items-center centeralign">
					<span class="bg-warning rounded ml-2 px-2 py-1"> <ngx-typed-js
							[strings]="[
                    'Actions',
                    'Accelerators',
                    'Analytics',
                    'Agents',
                    'Assistants',
                    'Applications'
                  ]"
							[shuffle]="false" [loop]="true" [typeSpeed]="30">
						<span class="typing"></span> </ngx-typed-js>
					</span>
				</h3>
				<p class="font-weight-normal d-flex flex-wrap custom-style p-3">
				

					<span class="badge custom-tag" *ngFor="let tag of HeroTags">{{tag}}</span>
				</p>

				<div>
				
					<button type="submit" class="btn btn-primary btn-icon mt-3"
						(click)="navigateToSignup()">
						Sign up <i class="fa-solid fa-arrow-right-long ml-3"></i>
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="../../../../assets/images/landing0.webp" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide> --> <!-- <div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0 text-left">
				<h1 class="font-weight-light mb-0">
					Use <a target="_blank" class=""
						href="https://app.genailia.com/generative-ai/ellmo">Genailia's
						eLLMo for </a>
				</h1>

				<h3
					class="font-weight-bold lh-1 d-flex align-items-center centeralign">
					<span class="bg-warning rounded ml-2 px-2 py-1"> <ngx-typed-js
							[strings]="[
                    'Generative AI',
                    'Multiple AI Models',
                    'Image Generation',
                    'Role-based Usage',
                    'GPTs | LLMs | RAG | Agents'
                  ]"
							[shuffle]="false" [loop]="true" [typeSpeed]="30">
						<span class="typing"></span> </ngx-typed-js>
					</span>
				</h3>



				<p class="font-weight-normal d-flex flex-wrap custom-style p-3">

					<span class="badge custom-tag" *ngFor="let tag of HeroTagsNew">{{tag}}</span>
				</p>

				<div>
					<button type="submit" class="btn btn-primary btn-icon mt-3"
						(click)="navigateToSignup()">
						Sign up <i class="fa-solid fa-arrow-right-long ml-3"></i>
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto"
					alt="eLLMo - Enterprise Large Language Model Outcomes"
					src="../../../../assets/images/landing1.webp" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide> -->
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0 text-left">
				<!-- <h2 class="font-weight-light mb-0">Home of</h2> -->
				<h2 class="custom-h2">
					Transform Your Business with <br />
					<span class="highlight-text">Genailia's Unified AI Platform</span>
				</h2>
				
				<h6 class="font-weight-bold mt-4 text-left mb-3">From
					cutting-edge AI to powerful LLM-based search and seamless
					experience optimization, Genailia delivers the tools and platform
					to make AI work for you.</h6>

				<p class="font-weight-normal d-flex flex-wrap custom-style p-1">
					<span class="badge custom-tag" *ngFor="let tag of HeroTagsNew">{{tag}}</span>
				</p>
				<a href=""
				onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;">
					<button class="btn btn-warning btn-icon mt-4" >Book a demo
						!</button>
				</a>

			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/images/landing0.webp" loading="lazy" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-2 pt-md-0 text-left">
				<!-- <h1 class="font-weight-light mb-0">TTS &amp; ASR</h1> -->
				<h2 class="font-weight-bold lh-1 text-left"
					style="line-height: 2.75rem;">

					Unleash the Power of <br /> <span
						class="bg-warning rounded px-2 py-1">Conversational AI</span> with
					eLLMo
				</h2>

				<h5 class="font-weight-bold mt-4 mb-0 text-left mb-3">Elevate
					experiences for Customers, Employees, and Agents by integrating AI
					with your current systems for more personalized and engaging
					interactions</h5>
				<!-- <h6 class="font-weight-normal font-italic mb-5">Emotional TTS |
					Voice Cloning | 2500+ voices | Text to Music</h6> -->

				<!-- <a target="_blank" rel="noreferrer noopener"
					href="https://calendly.com/genailia/30min"
					class="btn btn-warning btn-icon mt-4"> <i
					class="fa-regular fa-calendar mr-2"></i>Book a Demo!
				</a> -->
				<p class="font-weight-normal d-flex flex-wrap custom-style p-1">
					<span class="badge custom-tag" *ngFor="let tag of HeroTagsNew2">{{tag}}</span>
				</p>
				<a href=""
					onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;">
					<button class="btn btn-warning btn-icon mt-4">Book a demo
						!</button>
				</a>

			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/images/landing6.webp" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0 text-left">
				<!-- <h2 class="font-weight-light mb-0">Mix and Match</h2> -->
				<h3 class="font-weight-bold lh-1 text-left"
					style="line-height: 2.5rem;">
					Translate, Transcribe, and Speak in <br> <span
						class="bg-warning rounded px-2 py-1">100+ Languages</span>
				</h3>

				<h5 class="font-weight-bold mt-4 mb-0 text-left mb-3">Harness
					the power of AI to break language barriers with seamless
					translation, transcription, and voice synthesis.</h5>
				<!-- <h6 class="font-weight-normal font-italic mb-5">that let you
					create a unique solution for your needs!</h6> -->

				<!-- <a target="_blank" rel="noreferrer noopener"
					href="https://app.genailia.com/dashboard"
					class="btn btn-warning btn-icon mt-4"> <i
					class="fa-regular fa-calendar mr-2"></i>Sign in
				</a> -->
				<p class="font-weight-normal d-flex flex-wrap custom-style p-1">
					<span class="badge custom-tag" *ngFor="let tag of HeroTags">{{tag}}</span>
				</p>
				<a href="https://app.genailia.com" target="_blank"
					class="btn btn-warning btn-icon mt-4"> Try now! </a>


			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/images/landing7.webp" />
			</div>
		</div>
		</ng-template> </ngb-carousel>
	</section>


	<br />
	<!-- 
	<section class="container-fluid">
		<div class="row">
			<div class="col-12 text-center mb-5" data-aos="fade-up"
				data-aos-offset="100" data-aos-delay="80">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-question add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4">
						<span class="font-weight-light">The </span> Challenge
					</h3>
				</div>
			</div>
		</div>
		<div class="py-5 bg-light">
			<div class="container text-center">
				<h3 class="mb-4 font-weight-bold">Today's Enterprises Face
					Multifold Challenges</h3>
				<p class="lead mb-5"></p>
				<div class="row">
					<div class="col-md-4">
						<div class="card border-0 shadow-sm">
							<div class="card-body">
								<h4 class="card-title display-4">&gt; 50%</h4>
								<h5 class="card-text">Of Consumers Expect Services in Their
									Native Language</h5>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card border-0 shadow-sm">
							<div class="card-body">
								<h4 class="card-title display-4">&gt; 62%</h4>
								<h5 class="card-text">Of Companies Struggle to Maintain
									Consistent AI-Driven Customer Support</h5>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card border-0 shadow-sm">
							<div class="card-body">
								<h4 class="card-title display-4">&gt; 60%</h4>
								<h5 class="card-text">Of Enterprises Face Challenges in
									Scaling AI Across Departments</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->


	<div class="container" data-aos="fade-up">
		<!-- Dynamic Tabs -->
		<div class="row mb-2">
			<div *ngFor="let key of getKeys(content); let i = index"
				data-aos="fade-up" [attr.data-aos-delay]="80 * i"
				class="col-md-2 col-5 text-center rounding-new mb-2"
				style="border: 1px solid #071359"
				[ngClass]="{'bg-custom': selectedTab === key}"
				(click)="selectTab(key)">
				<span class="py-3 mb-0">{{ content[key].title }}</span>
			</div>
		</div>
		<!-- Dynamic Tab Content -->
		<div class="tab-content" id="myTabContent">
			<div *ngFor="let key of getKeys(content)" class="tab-pane fade"
				[ngClass]="{'show active': selectedTab === key}"
				[class.d-none]="selectedTab !== key">
				<div class="row">
					<div class="col-md-6">
						<h3 class="mb-4">{{ getContent(key).lead }}</h3>
						<!-- <p class="lead">{{ getContent(key).lead }}</p> -->
						<p>{{ getContent(key).description }}</p>
						<p>{{ getContent(key).details }}</p>
						<!-- 
                        <a href="#" class="btn btn-primary mt-4">{{
                            getContent(key).buttonText }}</a> -->
					</div>

					<div class="col-md-6 text-center">
						<div class="image-container">
							<img [src]="getContent(key).imageUrl"
								class="img-fluid image-round" alt="{{ getContent(key).title }}"
								loading="lazy"
								>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>


	<br /> <br />


	<!-- Our clients -->


	<app-platform></app-platform>

	<br /> <br /> <br> <br>
	<div class="row">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light"
					data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
					<i class="fa-solid fa-newspaper add2"></i>
				</div> -->
				<h3 class="font-weight-bold mt-4" data-aos="fade-up"
					data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
					<span class="font-weight-light">Proudly</span> Associated With
				</h3>
			</div>
		</div>
	</div>
	<br /> <br>
	<div class="py-3 marqueenew">


		<div class="logo-marquee-container">
			<div class="logo-marquee marquee-left-to-right">
				<div *ngFor="let logo of reversedLogos" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid" loading="lazy">
				</div>



			</div>
			<div class="logo-marquee marquee-right-to-left mt-2">
				<div *ngFor="let logo of logos" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid" loading="lazy">
				</div>


			</div>
		</div>
	</div>
	<br> <br> <br>
	<div class="row">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light"
					data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
					<i class="fa-solid fa-newspaper add2"></i>
				</div> -->
				<h3 class="font-weight-bold mt-4" data-aos="fade-up"
					data-aos-offset="100" data-aos-delay="80">
					<span class="font-weight-light">Our</span> Clients &amp; Partners
				</h3>
			</div>
		</div>
	</div>
	<br /> <br />
	<div class="py-3 marqueenew">


		<div class="logo-marquee-container">
			<div class="logo-marquee marquee-left-to-right">
				<div *ngFor="let logo of reversedLogos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid" loading="lazy">
				</div>



			</div>
			<div class="logo-marquee marquee-right-to-left mt-2">
				<div *ngFor="let logo of logos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid" loading="lazy">
				</div>


			</div>
		</div>
	</div>

	<!-- Our clients -->
	<!-- <div class="row">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<div class="icon-box mx-auto p-3 text-purple font-weight-light">
					<i class="fa-solid fa-newspaper add2"></i>
				</div>
				<h3 class="font-weight-bold mt-4" data-aos="fade-up">
					<span class="font-weight-light">Our</span> Clients &amp; Partners
				</h3>
			</div>
		</div>
	</div>
	<br /> <br /> -->
	<!-- <div class="py-3 marqueenew">


		<div class="logo-marquee-container">
			<div class="logo-marquee marquee-left-to-right">
				<div *ngFor="let logo of reversedLogos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>



			</div>
			<div class="logo-marquee marquee-right-to-left mt-2">
				<div *ngFor="let logo of logos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>


			</div>
		</div>
	</div> -->


	<!-- Our clients -->
	<br /> <br /> <br /> <br />

	<!-- 
	<div class="survey-background-container ">
		<div class="platform-features surveycontainer ">
			<h4 class="surveycontainer">Explore Genailia's Unified Platform</h4>

			<div class="survey-invitation">
				<p>At Genailia, we strive to revolutionize how businesses handle
					multilingual content and conversational AI. We're interested in
					understanding what products you currently utilize and how we can
					enhance your experience. Take our brief survey to compare what you
					get from competitors and discover the unique benefits of Genailia.
					Your insights are invaluable in helping us serve you better!</p>
				<a href="https://surveys.genailia.com/zs/Nz60jY" target="_blank"
					class="survey-button">Take the Survey</a><br /> <br /> <br />
			</div>
		</div>
	</div>
	<br /> <br /> <br /> -->
	<!-- Our offerings -->
	<!-- Our products -->
	<div class="container-fluid py-2">
		<section class="py-5 mb-4">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-12 text-center mb-4">
						<div class="flex justify-content-center align-items-center">
							<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light"
								data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
								<i class="fa-solid fa-newspaper add2"></i>
							</div> -->
							<h3 class="font-weight-bold mt-4" data-aos="fade-up"
								data-aos-offset="100" data-aos-delay="80">Unleash the
								Potential of Generative and Conversational AI - eLLMo</h3>
						</div>
					</div>
					<div class="col-md-6" data-aos="fade-up" data-aos-offset="100"
						data-aos-delay="80">
						<p>Utilize Genailia's intuitive low-code environment, equipped
							with Generative AI, to craft robust IVAs effortlessly. By taking
							advantage of Genailia's extensive pre-built assets, seamless
							integrations, and advanced language comprehension, businesses can
							swiftly design new conversational workflows, accelerating both
							time-to-market and time-to-value.</p>
						<div class="bullet-points">
							<p>Automatically generate use cases through Generative AI
								with Genailia.</p>
							<p>Integrate your own LLM, use Genailia models, or
								incorporate third-party models to facilitate AI-assisted use
								case creation.</p>
							<p>Employ pre-built content packs to design IVAs within a
								user-friendly drag-and-drop interface.</p>
						</div>
						<!-- <ul class="text-left">
							<li>Automatically generate use cases through Generative AI
								with Genailia.</li>
							<li>Integrate your own LLM, use Genailia models, or
								incorporate third-party models to facilitate AI-assisted use
								case creation.</li>
							<li>Employ pre-built content packs to design IVAs within a
								user-friendly drag-and-drop interface.</li>
						</ul> -->
						<a href=""
							onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;">
							<div>
								<button type="submit" class="btn btn-warning btn-icon mt-4">Book
									a Demo!</button>
							</div>
						</a>
					</div>
					<div class="col-md-6">
						<img src="../../../../assets/images/aiunified.webp"
							class="img-fluid custom-image-height" alt="Genailia Interfaces"
							data-aos="fade-up" data-aos-offset="100" data-aos-delay="80" loading="lazy" />
					</div>
				</div>
			</div>
		</section>
	</div>
	<!-- Our products -->
	<section class="container-fluid">
		<div class="row">
			<div class="col-12 text-center mb-4">
				<div class="flex justify-content-center align-items-center">
					<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-robot add2"></i>
					</div> -->
					<h3 class="font-weight-bold mt-4" data-aos="fade-up">
						<span class="font-weight-light">Genailia Linguistic AI
							Solutions - Lingo</span>
					</h3>
				</div>
			</div>
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-8">
						<p class="text-center">
							Transform how you communicate across borders with Genailia's
							cutting-edge Linguistic AI. Translate, interpret, and localize
							your content to connect with audiences worldwide, respecting
							linguistic and cultural diversity.<br />
						</p>
					</div>
				</div>
			</div>

			<section class="container-fluid">
				<div class="row">
					<div class="col-12 text-center mb-4">
						<div class="flex justify-content-center align-items-center">
							<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light">
								<i class="fa-solid fa-robot add2"></i>
							</div>
							<h3 class="font-weight-bold mt-4" data-aos="fade-up">
								<span class="font-weight-light">Benefits of Linguistic AI</span>
							</h3> -->
							<div class="container px-4 py-3 rounded mt-5"
								style="background-color: #071359;" data-aos="fade-up"
								data-aos-offset="100" data-aos-delay="80">


								<div
									class="row row-cols-1 row-cols-sm-2 row-cols-lg-5 g-4 mb-1 ">
									<div class="col">
										<a
											class="fixed-height-card text-decoration-none d-flex flex-column bg-white border border-1 border-dark rounded">
											<div class="p-3 p-md-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="16"
													height="16" fill="#ff6e10" class="bi bi-phone"
													viewBox="0 0 16 16">
							<path
														d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
							<path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
						  </svg>
												<h6 class="h6 fw-bold mt-2">Global Localization</h6>
												<p class="mt-2 small">Reach every market: Translate and
													adapt your content to every language and culture with ease.</p>
											</div>
										</a>
									</div>

									<div class="col">
										<a
											class="fixed-height-card text-decoration-none d-flex flex-column bg-white border border-1 border-dark rounded">
											<div class="p-3 p-md-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="16"
													height="16" fill="#966bf7" class="bi bi-controller"
													viewBox="0 0 16 16">
							<path
														d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z" />
							<path
														d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27" />
					 </svg>
												<h6 class="h6 fw-bold mt-2">Emotional Intelligence</h6>
												<p class="mt-2 small">Culturally aware content: Adjust
													sentiment to suit diverse audiences.</p>
											</div>
										</a>
									</div>

									<div class="col">
										<a
											class="fixed-height-card text-decoration-none d-flex flex-column bg-white border border-1 border-dark rounded">
											<div class="p-3 p-md-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="16"
													height="16" fill="#18bbd7" class="bi bi-ui-checks-grid"
													viewBox="0 0 16 16">
							<path
														d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1m9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1m0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0z" />
						  </svg>
												<h6 class="h6 fw-bold mt-2">Live Translation</h6>
												<p class="mt-2 small">Seamless interaction: Provide
													real-time translation for conversations, meetings, and
													documents.</p>
											</div>
										</a>
									</div>

									<div class="col">
										<a
											class="fixed-height-card text-decoration-none d-flex flex-column bg-white border border-1 border-dark rounded">
											<div class="p-3 p-md-4">
													<svg xmlns="http://www.w3.org/2000/svg" width="16"
														height="16" fill="#ed270b" class="bi bi-basket"
														viewBox="0 0 16 16">
								<path
															d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9zM1 7v1h14V7zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10m2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10m2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10m2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5m2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5" />
							  </svg>
												<h6 class="h6 fw-bold mt-2">Adaptive AI</h6>
												<p class="mt-2 small">Learning and evolving: AI that
													improves translation accuracy over time through continuous
													learning.</p>
											</div>
										</a>
									</div>

									<div class="col">
										<a
											class="fixed-height-card text-decoration-none d-flex flex-column bg-white border border-1 border-dark rounded">
											<div class="p-3 p-md-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="16"
													height="16" fill="#30c34a" class="bi bi-question-circle"
													viewBox="0 0 16 16">
							<path
														d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
							<path
														d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
						  </svg>
												<h6 class="h6 fw-bold mt-2">Cultural Customization</h6>
												<p class="mt-2 small">Localized content: Ensure your
													content fits the cultural preferences and nuances of your
													audience.</p>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center">
					<a href="https://app.genailia.com" target="_blank"
						class=" btn btn-warning btn-icon mt-4"> Try now! </a>
				</div>

			</section>

			<div class="d-none d-md-block col-3 border-left pl-4">
				<div data-aos="fade-up" [attr.data-aos-delay]="150 * index"
					data-aos-anchor-placement="top-bottom"
					*ngFor="let product of products; let index = index"
					[ngClass]="activeProductIndex === index ? 'active-product' : ''">
					<div class="product-border"></div>
					<p (click)="activeProductIndex = index"
						class="py-2 cursor-pointer add233">&nbsp;&nbsp;&nbsp;&nbsp;{{
						product.title }}</p>
				</div>
			</div>

			<div
				class="d-none d-md-block col-8 bg-purple-100 rounded pt-3 text-center shadow-sm h-fit"
				[attr.data-aos]="responsiveFade"
				data-aos-anchor-placement="top-bottom">
				<p class="text-primary1 font-weight-bold">{{
					products[activeProductIndex].description }}</p>
				<div class="relative">
					<img class="img-fluid rounded cursor-pointer"
						[src]="'assets/images/product' + (activeProductIndex + 1) + '.webp'"
						alt="Product Image {{ activeProductIndex + 1 }}" type="button"
						loading="lazy"
						(click)="
              showGif(products[activeProductIndex].gif, activeProductIndex)
            "
						data-bs-toggle="modal" data-bs-target="#exampleModal1" />
					<div class="play-button"
						(click)="
              showGif(products[activeProductIndex].gif, activeProductIndex)
            "
						data-bs-toggle="modal" data-bs-target="#exampleModal1">
						<i class="fa fa-play-circle"></i>
					</div>
				</div>
			</div>

			<div class="d-md-none col-12" data-aos="fade-up">
				<ngb-carousel [pauseOnHover]="true" [showNavigationArrows]="true"
					[showNavigationIndicators]="false" [interval]="7000">
				<ng-template ngbSlide
					*ngFor="let product of products; let index = index">
				<p class="mb-0 font-weight-bold h5 text-purple text-center">{{
					index > 8 ? "" : "0" }}{{ index + 1 }}. {{ product.title }}</p>
				<div class="product-border-mobile mb-3"></div>
				<div
					class="bg-purple-100 rounded pt-3 text-center shadow-sm p-2 mb-5">
					<p class="text-sm text-primary font-weight-bold">{{
						product.description }}</p>
					<img class="img-fluid rounded cursor-pointer"
						[src]="'assets/images/product' + (index + 1) + '.webp'"
						alt="Product Image {{ index + 1 }}"
						loading="lazy"
						(click)="showGif(product.gif, index)" data-bs-toggle="modal"
						data-bs-target="#exampleModal1" />
					<div class="play-button" (click)="showGif(product.gif, index)"
						data-bs-toggle="modal" data-bs-target="#exampleModal1">
						<i class="fa fa-play-circle"></i>
					</div>
					<button [routerLink]="product.route" type="button"
						class="btn btn-primary btn-round btn-icon mt-3 btn-sm">
						<i class="fa-solid fa-shapes mr-2"></i> Try Now
					</button>
				</div>
				</ng-template> </ngb-carousel>
			</div>

			<div class="modal" id="exampleModal1" tabindex="-1"
				aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered custom-modal-width">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel"></h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<div class="modal-body text-center">
							<video id="productVideo" class="img-fluid rounded" controls
								autoplay loop muted>
								<source id="productVideoSource" type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


	<br>
	<div class="row">
		<div class="col-12 text-center mb-5" data-aos="fade-up"
			data-aos-offset="100" data-aos-delay="80">
			<div class="flex justify-content-center align-items-center">
				<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light"
					data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
					<i class="fa-solid fa-shuttle-space add2"></i>
				</div> -->
				<h3 class="font-weight-bold mt-4" data-aos="fade-up"
					data-aos-offset="100" data-aos-delay="80">
					<span class="font-weight-light"> Our </span> Enterprise-Grade
					Features
				</h3>
			</div>
		</div>
	</div>
	<section class="py-5">
		<div class="container">
			<div class="row">
				<div class="col-md-3" *ngFor="let card of cards" data-aos="fade-up"
					data-aos-anchor-placement="top-bottom">
					<div class="custom-card shadow-sm h-100">
						<div
							class="custom-card-body d-flex flex-column align-items-center justify-content-between h-100">
							<i [class]="card.iconClass"></i>
							<h5 class="custom-card-title text-center">{{ card.title }}</h5>
							<p class="custom-card-text text-center">{{ card.description
								}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>



	<!-- AI services -->
	<!-- Tailored solution -->

	<section class="container ">
		<div class="row">
			<div class="col-12 col-md-12 mb-4 text-center" data-aos="fade-up"
				[attr.data-aos-anchor-placement]="anchorPlacement">
				<div class="flex justify-content-center align-items-center">
					<!-- <div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-shuttle-space add2"></i>
					</div> -->
					<h3 class="font-weight-bold mt-4">
						<span class="font-weight-light">Boosting Customer, Agent,
							&amp; Employee</span> Experience Across Domains!
					</h3>
				</div>
			</div>
			<!-- <div data-aos="fade-up" [attr.data-aos-delay]="80 * index"
            class="col-12 col-md-4 col-lg-3 text-center py-4 cursor-pointer tailored-card rounded logo-img"
            *ngFor="let sol of tailored; let index = index">
            <a [routerLink]="sol.link"> <img class="mb-3" alt="property"
                [src]="'assets/images/tts' + (index + 1) + '.svg'" />

                <p class="mb-1 font-weight-bold">{{ sol.title }}</p>
                <p class="text-sm">{{ sol.desc }}</p></a>
        </div> -->
			<a [routerLink]="sol.link"
				class="col-12 col-md-4 col-lg-3 text-center py-4 cursor-pointer  rounded logo-img"
				*ngFor="let sol of tailored; let index = index" data-aos="fade-up"
				[attr.data-aos-delay]="80 * index">
				<div class="shadow-card">
					<i class="fa fa-3x" [ngClass]="sol.icon" aria-hidden="true"></i>
					<p class="mb-1 font-weight-bold">{{ sol.title }}</p>
					<p class="text-sm">{{ sol.desc }}</p>
				</div>
			</a>

		</div>
	</section>


	<!-- 
<div class="container-fluid">


	<br /> <br /> <br /> <br />

	<section class="container">
		<div class="row">
			<div class="col-12 text-center mb-5" data-aos="fade-up"
				data-aos-offset="100" data-aos-delay="80">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-shuttle-space add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4">
						<a routerLink="/ai-capabilities" class="font-weight-light">Our
							AI Capabilities</a>&nbsp;-&nbsp; <a routerLink="/ai-transformation">Your
							AI Transformation</a>
					</h3>
				</div>
			</div>
		</div>
		<div class="myclass">
			<div class="add4 col-12 col-md-3 mb-3 mb-md-0"
				*ngFor="let card of serviceCards; let index = index"
				[attr.data-aos-delay]="200 * index" data-aos="fade-right">
				<div class="card service-box text-center d-block h-100">
					<i [ngClass]="card.iconClass"></i>

					<h6 class="font-weight-bold mt-3">{{ card.title }}</h6>
					<p
						class="service-box_description font-weight-normal mt-4 mb-0 text-xs">
						{{ card.description }}</p>
				</div>
			</div>
		</div>
	</section>
	<br /> <br /> <br /> <br />

 -->

	<!-- Our services -->

	<!-- Try our products -->
	<!-- <div class="row">
      <div class="col-12 text-center my-auto">
        <h3 class="font-weight-bold mb-4">Get a walkthrough of our products or See them for yourself!</h3>

        <div class="d-block d-md-flex justify-content-center" style="gap: 1rem">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://calendly.com/genailia/30min"
            class="btn btn-neutral btn-icon"
          >
            <i class="fa-regular fa-calendar mr-2"></i>
            Schedule a quick call
          </a>

          <button type="button" class="btn btn-warning btn-icon mt-4 mt-md-0">
            Signup to try our products!
            <i class="fa-solid fa-arrow-right-long ml-2"></i>
          </button>
        </div>
      </div>
    </div> -->
	<!-- 
    <section class="container">

		<div class="row">
			<div class="col-12 add3 touch-card text-center">
				<div class="position-relative w-100 h-100">
					<!-- <img class="wave1 position-absolute d-none d-md-block" src="assets/images/wave1.svg" alt="Leader" />
                    <img class="wave2 position-absolute d-none d-md-block" width="60%" src="assets/images/wave2.webp"
                        alt="Leader" /> 

					<img class="person1 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/images/t1.webp" alt="Bot" /> <img
						class="person2 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/images/t2.webp" alt="ellmo" />
					<img class="person3 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/images/t3.webp" alt="ellmo" />
					<img class="person4 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/images/t1.webp" alt="Bot" />

					<div class="touch-card_content mx-auto z-index-10">
						<h3 class="font-weight-bold mb-4 md-mb-5 text-white mx-auto"
							data-aos="fade-up" data-aos-offset="100" data-aos-delay="80"
							[attr.data-aos-anchor-placement]="anchorPlacement"
							data-aos="fade-up">Get a walkthrough of our products or See
							them for yourself!</h3>
						<!-- <a target="_blank" rel="noreferrer noopener"
							href="https://calendly.com/genailia/30min"
							class="btn btn-neutral btn-icon d-block w-fit mx-auto"
							data-aos="fade-up" data-aos-delay="150"
							[attr.data-aos-anchor-placement]="anchorPlacement"> <i
							class="fa-regular fa-calendar mr-2"></i> Schedule a quick call
						</a> 
						<div>
							<link
								href="https://assets.calendly.com/assets/external/widget.css"
								rel="stylesheet" />
							<a href=""
								onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;"
								class="btn btn-neutral btn-icon d-block w-fit mx-auto"
								data-aos="fade-up" data-aos-delay="150"
								[attr.data-aos-anchor-placement]="anchorPlacement"> <i
								class="fa-regular fa-calendar mr-2"></i> Schedule a quick call
							</a>
						</div>

						<button (click)="navigateToSignup()" type="button"
							class="btn btn-warning btn-icon mt-4" data-aos="fade-up"
							data-aos-delay="150"
							[attr.data-aos-anchor-placement]="anchorPlacement">
							Signup to try our products! <i
								class="fa-solid fa-arrow-right-long ml-2"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
	 
	<!-- Try our products -->
	<!-- <br /> <br /> <br /> <br /> 
	
	<section class="container">
		<div class="row">
			<div class="col-12 col-md-5" data-aos="fade-right"
				data-aos-delay="100" data-aos-offset="50">
				<h3 class="mb-0 font-weight-light lh-1 mt-4">GenAI-LI-A</h3>
				<h3 class="font-weight-bold">Revolution</h3>

				<p class="">Empowering AI transformations since 2021, we
					identified the unmet need for advanced AI solutions tailored to
					startups, SMEs, and enterprises. Our innovative, scalable products
					drive business growth by breaking down barriers in a dynamic
					market. With Genailia, experience seamless integration of AI
					capabilities that accelerate your success and position your
					business at the forefront of the AI revolution.</p>

				<div class="d-block d-md-flex mt-5 custom-gap">
					<a target="_blank" rel="noreferrer noopener"
						href="https://calendly.com/genailia/30min"
						class="btn btn-warning btn-icon mt-4"> <i
						class="fa-regular fa-calendar mr-2"></i> Consult with us
					</a>
					<button type="button" class="btn btn-neutral btn-icon mt-4 mt-md-0"
						(click)="myservice.openContactUsForm()">
						<i class="fa-regular fa-envelope mr-2"></i> Contact us
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-md-2 my-lg-auto mt-4"
				[attr.data-aos]="responsiveFade">
				<img class="img-fluid rounded mb-4"
					src="assets/images/feature-two-1.webp" alt="feature-two-1" /> <img
					class="img-fluid rounded" src="assets/images/feature-two-2.webp"
					alt="feature-two-2" />
			</div>

			<div class="d-none d-md-block col-md-3 mt-lg-0 mt-4">
				<img class="img-fluid rounded"
					src="assets/images/feature-two-3.webp" alt="feature-two-3" />
			</div>

			<div class="d-none d-md-block col-md-2 my-lg-auto mt-4"
				data-aos="fade-right">
				<img class="img-fluid rounded"
					src="assets/images/feature-two-4.webp" alt="feature-two-4" />
			</div>
		</div>
	</section>
	<br /> <br /> <br /> <br /> -->

	<!-- AI services -->
	<!-- 
<section class="container-fluid overflow-hidden">
	<div class="row no-gutters">
		<div class="col-12 col-md-5" data-aos="fade-up">
			<h3 class="mb-0 font-weight-light lh-1 mt-4" data-aos="fade-right">
				Affordable &amp; Democratized</h3>
			<h3 class="font-weight-bold" data-aos="fade-right">AI for
				everyone</h3>

			<p data-aos="fade-right">Genailia delivers diverse solutions for
				diverse applications, ensuring that the technology provided is
				reliable, trustworthy, and cutting-edge. With a strong commitment to
				the latest AI innovations, Genailia empowers businesses with
				exceptional possibilities. These democratized, affordable solutions
				make advanced AI accessible to all, without compromising on quality
				or security. Emphasizing best-in-class standards of excellence and
				confidentiality, Genailia builds strong, trusted partnerships,
				helping clients achieve their goals with confidence.</p>
		</div>

		<div class="col-12 col-md-7">
			<div class="row no-gutters">
				<div class="col-12 col-sm-6 col-md-4 mb-4 text-center"
					data-aos="fade-left" [attr.data-aos-delay]="100 * index"
					*ngFor="let card of aiServices; let index = index">
					<div class="card aiCard h-100 pt-4">
						<img class="rounded-circle mx-auto aiCard_img" width="100"
							height="100"
							[src]="'assets/images/aiservice' + (index + 1) + '.webp'"
							alt="aiservice {{ index + 1 }}" />
						<div class="mt-auto pb-3">
							<p class="text-primary font-weight-bold">{{ card.title }}</p>
							<a rel="noreferrer noopener" [href]="card.route"
								class="text-sm text-primary mx-auto tailored-link"> Know
								more <i class="fa-solid fa-arrow-right-long ml-2"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->


	<div class="container py-3" data-aos="fade-up" data-aos-offset="100"
		data-aos-delay="80" *ngIf="relatedBlogs && relatedBlogs.length > 0;">
		<h4 class="mb-6" data-aos="fade-up" data-aos-offset="100"
			data-aos-delay="80">Our Recent Blogs &amp; Thoughts</h4>
		<br />
		<div class="post-container">
			<div *ngFor="let post of relatedBlogs" class="post"
				data-aos="fade-up" data-aos-offset="100" data-aos-delay="80">
				<a
					[routerLink]="['../blog/post', formatToSlugWithGuid(post.title,post.guid)]">
					<img [src]="post.imageUrl" alt="Post Image" loading="lazy"
					class="post-image w-24 h-24 object-cover mx-auto" />
					
					<!-- Fixed size with centering -->
					<div class="post-content">
						<h4 class="post-title">{{ post.title }}</h4>
						<p class="post-description" [title]="post.description">{{
							post.description }}</p>
						<div class="post-meta">
							<span>Category: {{ post.category }}</span> <span>By {{
								post.creator }}</span>
						</div>
					</div>
				</a>
			</div>
		</div>

	</div>
	<br /> <br /> <br /> <br />



	<!-- Our products -->
</div>
<div class="py-3 container" data-aos="fade-up" data-aos-offset="100"
	data-aos-delay="80" *ngIf="relatedBlogs && relatedBlogs.length > 0;">
	<h4 class="mb-6">Latest News</h4>
	<br />
	<div class="post-container">
		<div *ngFor="let post of annoucements" data-aos="fade-up"
			data-aos-offset="100" data-aos-delay="80" class="post">
			<a
				[routerLink]="['../announcement/post', formatToSlugWithGuid(post.title,post.guid)]">
				<img [src]="post.imageUrl" alt="Post Image" loading="lazy"
		class="post-image w-24 h-24 object-cover mx-auto" />
				<div class="post-content">
					<h4 class="post-title">{{ post.title }}</h4>
					<p class="post-description" [title]="post.description">{{
						post.description }}</p>
					<div class="post-meta">
						<span>Category: {{ post.category }}</span> <span>By {{
							post.creator }}</span>
					</div>
				</div>
			</a>
		</div>
	</div>

</div>

<br />
<br />
<br />
<!-- FAQ -->
<section class="container">
	<div class="row">
		<div class="col-12 col-md-12">
			<div id="faq-anchor"
				class="d-flex align-items-center mb-4 custom-gap"
				[attr.data-aos]="responsiveFade" data-aos-anchor="#faq-anchor"
				[attr.data-aos-anchor-placement]="anchorPlacement">

				<div class="">
					<h3 class="mb-0 font-weight-light lh-1 mt-4">FAQ's</h3>
					<h3 class="font-weight-bold">Frequently asked questions</h3>
				</div>
			</div>

			<ul id="faq-anchor2" ngbNav #nav="ngbNav" [(activeId)]="activeNav"
				class="nav-pills">
				<li [ngbNavItem]="1" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>General</a> <ng-template ngbNavContent> <ng-container
						*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

				<li [ngbNavItem]="2" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="200"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>Pricing</a> <ng-template ngbNavContent> <ng-container
						*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

				<li [ngbNavItem]="3" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="400"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>Languages</a> <ng-template ngbNavContent>
					<ng-container *ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

				<li [ngbNavItem]="4" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>Products</a> <ng-template ngbNavContent> <ng-container
						*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

				<li [ngbNavItem]="5" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>Enterprise</a> <ng-template ngbNavContent>
					<ng-container *ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

				<li [ngbNavItem]="6" [attr.data-aos]="responsiveFade"
					data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
					[attr.data-aos-anchor-placement]="anchorPlacement"><a
					ngbNavLink>Support</a> <ng-template ngbNavContent> <ng-container
						*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>
			</ul>

			<div [ngbNavOutlet]="nav" class="mt-4"></div>
		</div>
	</div>
</section>
<!-- FAQ -->

<!-- Contact us -->
<app-contact></app-contact>
<!-- Contact us -->

<!--
<div class="row">
  <div class="col-md-6">
    <div class="form-group has-success">
      <input type="text" placeholder="Success" class="form-control form-control-alternative is-valid" />
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group has-danger">
      <input type="email" placeholder="Error Input" class="form-control form-control-alternative is-invalid" />
    </div>
  </div>
</div> -->

<ng-template #faqTemplate> <ngb-accordion
	#acc="ngbAccordion" activeIds="ngb-panel-0"> <ngb-panel
	[title]="faq.question" *ngFor="let faq of faqs[activeNav - 1]">
<ng-template ngbPanelContent>
<div [innerHTML]="faq.answer" class="text-left"></div>
</ng-template> </ngb-panel> </ngb-accordion> </ng-template>
